@import 'consts.scss';

div.pro-gallery {
  //common
  .gallery-item-container {
    //===================================================================
    //image hover animations

    &.zoom-in-on-hover:not(.hide-hover) {

      .gallery-item-hover:not(.hide-hover), .gallery-item-content, .hover-info-element {
        transition: transform 2.2s cubic-bezier(.14, .4, .09, .99) !important;
    }
      &.simulate-hover,
      &:hover {
        .gallery-item-content {
          transform: scale(1.1);
        }
        .gallery-item-hover:not(.hide-hover) {
          transform: scale(1.110); //make sure overlay cover all edges of an image.
        }
        .gallery-item-hover:not(.hide-hover) .hover-info-element {
          transform: scale(calc(1/1.110));
        }

      }
    }

    &.blur-on-hover:not(.hide-hover) {
      .gallery-item-content {
        transition: filter .4s linear !important;
      }

      &.simulate-hover,
      &:hover {
        .gallery-item-content {
          .gallery-item {
            filter: blur(6px);
          }
        }
      }
    }

    &.grayscale-on-hover:not(.hide-hover) {
      .gallery-item-content {
        transition: filter .6s ease !important;
      }

      &.simulate-hover,
      &:hover {
        .gallery-item-content {
          filter: grayscale(1);
        }
      }
    }

    &.shrink-on-hover:not(.hide-hover) {
      transition: background-color .4s ease !important;

      .gallery-item-content,
      .gallery-item-hover:not(.hide-hover) {
        transition: transform .4s ease !important;
      }

      &.simulate-hover,
      &:hover {
        background-color: transparent !important;

        .gallery-item-content {
          transform: scale(0.985);
        }

        .gallery-item-hover:not(.hide-hover) {
          transform: scale(0.985);
        }
      }
    }

    &.invert-on-hover:not(.hide-hover) {
      .gallery-item-content {
        transition: filter .6s ease !important;
      }

      &.simulate-hover,
      &:hover {
        .gallery-item-content {
          filter: invert(1);
        }
      }
    }

    &.color-in-on-hover {
      .gallery-item-content {
        filter: grayscale(1);
        transition: filter .6s ease !important;
      }

      &.simulate-hover:not(.hide-hover),
      &:hover:not(.hide-hover) {
        .gallery-item-content {
          filter: grayscale(0);
        }
      }
    }

    &.darkened-on-hover {
      .gallery-item-content {
        filter: brightness(1);
        transition: filter .6s ease !important;
      }

      &.simulate-hover:not(.hide-hover),
      &:hover:not(.hide-hover) {
        .gallery-item-content {
          filter: brightness(0.7);
        }
      }
    }

    //&.slide-up-on-hover:not(.hide-hover) {
    //  &.simulate-hover,
    //  &:hover {
    //    transition: transform .8s ease !important;
    //    transform: translateY(-5%);
    //  }
    //}

    //&.expand-on-hover:not(.hide-hover) {
    //  &.simulate-hover,
    //  &:hover {
    //    transition: transform .4s ease !important;
    //    transform: scale(1.03);
    //    z-index: 9999;
    //  }
    //}
  }

  //regular hover
  .gallery-item-container:not(.invert-hover) {

    .gallery-item-hover {
      transition: none;

      &:before {
        opacity: 0;
      }

      .gallery-item-hover-inner {
        opacity: 0;
      }

    }

    .gallery-item-hover.force-hover,
    &:hover .gallery-item-hover:not(.hide-hover) {

      transition: none;

      &:before {
        opacity: 1;
        background: $bg-dark-opacity;
      }

      .gallery-item-hover-inner,
      .info-member:not(.hidden) {
        opacity: 1 !important;
      }
    }

    //===================================================================
    //overlay animations

    &.hover-animation-fade-in {
      .gallery-item-hover {
        transition: filter .4s ease, opacity .4s ease !important;

        &:before {
          transition: filter .4s ease, opacity .4s ease !important;
          filter: opacity(0);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .4s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          filter: opacity(1);
        }
      }
    }

    &.hover-animation-expand {
      .gallery-item-hover {
        transition: transform .4s ease, filter .2s ease, opacity .2s ease !important;

        &:before {
          transition: transform .4s ease, filter .2s ease, opacity .2s ease !important;
          transform: scale(0.9);
          filter: opacity(0);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .2s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          transform: scale(1);
          filter: opacity(1);
        }
      }
    }

    &.hover-animation-slide-up {

      .gallery-item-hover {
        transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;

        &:before {
          transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;
          transform: translateY(100%);
          filter: opacity(0);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .4s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          transform: translateY(0);
          filter: opacity(1);
        }
      }
    }

    &.hover-animation-slide-right {
      .gallery-item-hover {
        transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;

        &:before {
          transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;
          transform: translateX(-100%);
          filter: opacity(0);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .4s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          transform: translateX(0);
          filter: opacity(1);
        }
      }
    }

    &.hover-animation-slide-left {
      .gallery-item-hover {
        transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;

        &:before {
          transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;
          transform: translateX(100%);
          filter: opacity(0);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .4s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          transform: translateX(0);
          filter: opacity(1);
        }
      }
    }

    &.hover-animation-slide-down {

      .gallery-item-hover {
        transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;

        &:before {
          transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;
          transform: translateY(-100%);
          filter: opacity(0);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .4s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          transform: translateY(0);
          filter: opacity(1);
        }
      }
    }

    //&.hover-animation-radius {
    //  .gallery-item-hover {
    //    &:before {
    //      transform: scale(0);
    //      border-radius: 100%;
    //    }
    //  }
    //
    //  .gallery-item-hover.force-hover,
    //
    //  &:hover .gallery-item-hover:not(.hide-hover) {
    //
    //      transition: opacity .2s ease .6s !important;
    //
    //
    //    &:before {
    //      transition: transform .8s ease, border-radius .8s ease !important;
    //      transform: scale(1);
    //      border-radius: 0;
    //    }
    //  }
    //}
  }

  //invert-hover
  .gallery-item-container.invert-hover {

    .gallery-item-hover {
      transition: none;

      &:before {
        opacity: 1;
        background: $bg-dark-opacity;
      }

      .gallery-item-hover-inner {
        opacity: 1;
      }

      .gallery-item-hover-inner,
      .info-member:not(.hidden) {
        opacity: 1 !important;
      }

    }

    .gallery-item-hover.force-hover,
    &:hover .gallery-item-hover:not(.hide-hover) {

      transition: none;

      &:before {
        opacity: 0;
      }

      .gallery-item-hover-inner,
      .info-member:not(.hidden) {
        opacity: 0 !important;
      }
    }

    //===================================================================
    //overlay animations

    &.hover-animation-fade-in {
      .gallery-item-hover {
        transition: filter .4s ease, opacity .4s ease !important;

        &:before {
          transition: filter .4s ease, opacity .4s ease !important;
          filter: opacity(1);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .4s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          filter: opacity(0);
        }
      }
    }

    &.hover-animation-expand {
      .gallery-item-hover {
        transition: transform 0.4s ease, filter .2s ease, opacity .2s ease !important;

        &:before {
          transition: transform 0.4s ease, filter .2s ease, opacity .2s ease !important;
          transform: scale(1);
          filter: opacity(1);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .2s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          transform: scale(0.9);
          filter: opacity(0);
        }
      }
    }

    &.hover-animation-slide-up {

      .gallery-item-hover {
        transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;

        &:before {
          transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;
          transform: translateY(0);
          filter: opacity(1);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .4s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          transform: translateY(100%);
          filter: opacity(0);
        }
      }
    }

    &.hover-animation-slide-right {
      .gallery-item-hover {
        transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;

        &:before {
          transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;
          transform: translateX(0);
          filter: opacity(1);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .4s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          transform: translateX(-100%);
          filter: opacity(0);
        }
      }
    }

    &.hover-animation-slide-left {
      .gallery-item-hover {
        transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;

        &:before {
          transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;
          transform: translateX(100%);
          filter: opacity(1);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .4s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          transform: translateX(0);
          filter: opacity(0);
        }
      }
    }

    &.hover-animation-slide-down {
      .gallery-item-hover {
        transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;

        &:before {
          transition: transform .4s cubic-bezier(.3, .13, .12, 1), filter .5s ease, opacity .5s ease !important;
          transform: translateY(-100%);
          filter: opacity(1);
        }

        .gallery-item-hover-inner,
        .info-member:not(.hidden) {
          transition: opacity .4s ease;
        }
      }

      .gallery-item-hover.force-hover,
      &:hover .gallery-item-hover:not(.hide-hover) {

        &:before {
          transform: translateY(0);
          filter: opacity(0);
        }
      }
    }
  }
}
